
















































































timeHelpers = require '../helpers/time_entries'

module.exports =
  props: ['resources_json', 'time_entries', 'pauses_json', 'date', 'url', 'task_keys', 'editable', 'event_json',
          'expense_url', 'expenses_json', 'day_rates', 'per_diems_url', 'user_per_diem', 'expense', 'is_rest_day',
          'crew_member_id']
  data: ->
    timeEntries: @loadTimeEntriesJson(JSON.parse(@time_entries))
    pauses: @loadJson(JSON.parse(@pauses_json), 'pause')
    tasks: JSON.parse(@task_keys)
    per_diem: _.get(JSON.parse(@user_per_diem), 'amount', 0)
    event: JSON.parse(@event_json)
    rates: JSON.parse(@day_rates)
    resources: JSON.parse(@resources_json) || []
    error: null
    success: null
    amount: JSON.parse(@user_per_diem) || null
  computed:
    unsavedTimeEntries: ->
      @timeEntries.filter (e) => !e.unsaved
    mileages: ->
      _.flatten(@unsavedTimeEntries.map((e) => e.timeEntry.mileages))
    generators: ->
      _.flatten(@unsavedTimeEntries.map((e) => e.timeEntry.generators))
    sidebar_resources: ->
      _.compact(@resources.map (resource) =>
        mileages = @mileages.filter (m) => m.mileage.itemId == resource.id &&
          @isTimeEntryIdPresent(m.mileage.timeEntryId)
        if mileages.length > 0
          { resource: resource, mileage: @summ_mileage(mileages) }
        else
          false
      )
    sidebar_generators: ->
      _.compact(@resources.map (resource) =>
        generators = @generators.filter (m) => m.generator.itemId == resource.id &&
          @isTimeEntryIdPresent(m.generator.timeEntryId)
        if generators.length > 0
          { resource: resource, generator: @summ_generator(generators) }
        else
          false
      )
    rest_day: ->
      if @is_rest_day == 'Ruhetag' then true else false
    isEditable: ->
      @editable && @editable != "false"
    per_diems: ->
      if @amount
        if @amount.extreme_day
          value = @tariff[0]?.small_rate
        else
          value = @tariff[0]?.higher_rate
        @checkBreakfast(value)
    tariff: ->
      @rates.filter (e) => e.country == @amount.country
    singleDayEvent: ->
      moment.parseZone(@event.start_date).isSame(@event.end_date, 'day')
    workingTime: ->
      _(@crewMemberTimeEntries).sumBy (e) => timeHelpers.entryDuration(e.timeEntry)
    undeletedTimeEntries: ->
      @timeEntries.filter (e) -> !e.timeEntry.deleted
    crewMemberTimeEntries: ->
      @undeletedTimeEntries.filter (e) =>
        e.timeEntry.crewMemberId == @crew_member_id
    shiftTime: ->
      start =
        _.chain(@undeletedTimeEntries)
        .map (e) -> e.timeEntry.startTime
        .compact()
        .map (t) -> moment.parseZone(t)
        .sortBy()
        .first()
        .value()
      end =
        _.chain(@undeletedTimeEntries)
        .map (e) -> e.timeEntry.endTime
        .compact()
        .map (t) -> moment.parseZone(t)
        .sortBy()
        .last()
        .value()
      end.diff(start) if start && end
    taskTimes: ->
      _.chain(@crewMemberTimeEntries)
      .filter (e) -> e.timeEntry.task
      .groupBy (e) -> e.timeEntry.task
      .mapValues (group) =>
         _(group).sumBy (e) => timeHelpers.entryDuration(e.timeEntry)
      .value()
  methods:
    isTimeEntryIdPresent: (id) ->
      @crewMemberTimeEntries.some (el) -> el.timeEntry.id == id
    summ_mileage: (mileages) ->
      _.sumBy(mileages, (m) => parseFloat(m.mileage.mileage))
    summ_generator: (generators) ->
      summ_all = generators.reduce(((summ, i) =>
        summ + moment.parseZone(i.generator.endTime).diff(moment.parseZone(i.generator.startTime))), 0)
    checkBreakfast: (val) ->
      if @amount.breakfast
        breakfast = val*0.8
        breakfast.toFixed(2)
      else
        val
    Diem: (value) ->
      @amount = value
    updateTimeEntries: (data) ->
      @pauses = @loadJson data.pauses, 'pause'
      @timeEntries = @loadTimeEntriesJson data.time_entries, 'timeEntry'
    loadJson: (json, resourceKey) ->
      _.map json, (timeEntry) ->
        "#{resourceKey}":
          _.merge(_.mapKeys(timeEntry, (v, k) -> _.camelCase(k)), { deleted: false })
        collapsed: true
        isValid: true
        unsaved: false
    loadTimeEntriesJson: (json) ->
      @loadJson(json, 'timeEntry').map (timeEntry) =>
        timeEntry.timeEntry.mileages = @loadJson(timeEntry.timeEntry.mileages, 'mileage')
        timeEntry.timeEntry.generators = @loadJson(timeEntry.timeEntry.generators, 'generator')
        timeEntry
