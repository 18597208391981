import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("Form field enable toggle controller connected...")
  }

  change(event) {
    this.element.querySelectorAll("input, select, textarea").forEach(function(element) {
      if (element == event.target) {
        return
      }
      if (element.disabled) {
        element.disabled = false
      } else {
        element.disabled = true
      }
    })
  }

}
