




































attachmentsHelpers = require '../helpers/attachments'

module.exports =
  props: ['label', 'accept', 'attachment', 'parent']
  data: ->
    file: null
  computed:
    fileName: ->
      return @attachment.file_filename if @attachment
      if @file then @file.name else ''
  methods:
    onDeleteClick: ()->
      @deleteFile() if confirm(@$t('attachment.confirm_destroy', {name: @fileName}))
    deleteFile: ()->
      if @attachment
        attachmentsHelpers.removeAttachment(@attachment.id, @parent.path, @parent.id)
      @file = null
      @$refs.file.value = ''
      @$emit('change', null)
      @$emit('update:attachment', null)
    onFileChange: (event)->
      @file = event.target.files[0]
      @$emit('update:attachment', null)
      @$emit('change', @file)
