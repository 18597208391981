import L from 'leaflet'
import polyline from 'polyline'
import 'leaflet.fullscreen'
import 'leaflet.extra-markers'

# Stylesheets
require 'leaflet/dist/leaflet.css'
require 'leaflet.extra-markers.css'
require 'leaflet.fullscreen/Control.FullScreen.css'

map_url = 'https://maps.omniscale.net/v2/{id}/style.default/{z}/{x}/{y}.png'
map_attribution = '&copy; 2023 &middot; <a href="https://maps.omniscale.com/">Omniscale</a> ' +
    '&middot; Map data: <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
map_omniscale_id = 'roadshow-2f0e3f13'

export default class LeafletMap
  constructor: (element) ->
    @map = L.map element
    L.tileLayer map_url,
      id: map_omniscale_id
      attribution: map_attribution
    .addTo @map
    @map.setView [51.3, 10.5], 4
    @map.attributionControl.setPrefix false
    L.control.fullscreen().addTo(@map)

  layerFromGeoJson: (data) ->
    for feature in data
      if feature.geometry.coordinatesEncoded?
        feature.geometry.coordinates = polyline.decode(feature.geometry.coordinatesEncoded)
    L.geoJson data,
      style: (feature) ->
        color: feature.properties.stroke
        weight: 3
        opacity: 1
      pointToLayer: (point, latlng) ->
        L.marker latlng,
          icon: L.ExtraMarkers.icon
            icon: 'fa-number'
            markerColor: point.properties.color || '#888'
            svg: true
            number: point.properties.number
            iconSize: [30, 32]
            iconAnchor: [15, 32]
    .bindPopup (layer) ->
      "<div class=\"marker-title\">#{layer.feature.properties.title}</div>" +
      "<div class=\"marker-description\">#{layer.feature.properties.description}</div>"

  addGeoJson: (data) ->
    @map.removeLayer(@featureLayer) if @featureLayer
    @featureLayer = @layerFromGeoJson(data).addTo(@map)
    @fitBounds(@featureLayer)

  loadGeoJson: (url) ->
    $.getJSON url, (data) =>
      layer = @addGeoJson(data)

  fitBounds: (layer) ->
    bounds = layer.getBounds()
    if bounds.isValid()
      @map.fitBounds layer.getBounds(),
        maxZoom: 13
        padding: [15, 15]
