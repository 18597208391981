















































module.exports =
  props: ['is_rest_day', 'timeEntry', 'collapsed', 'defaultDate', 'isValid', 'unsaved', 'tasks', 'deleted', 'editable',
          'resources', 'save_resources', 'crew_member_id']
  data: ->
    startTimeErrors: []
    endTimeErrors: []
    validGenerator: false
    timeGenerator: null
  methods:
    expand: ->
      return unless @editable
      @$emit('expand')
      @$emit('update:collapsed', false)
    collapse: ->
      @$emit('update:collapsed', true) if @isTimeEntryValid
    remove: ->
      @timeEntry.deleted = true if confirm(@$t('time_entry.confirm_remove'))
  computed:
    rest_day: ->
      if @is_rest_day == 'Ruhetag' then true else false
    tasks_works_day: ->
      if @is_rest_day
        @tasks
      else
        @tasks.filter (e) -> e != 'rest'
    totalTime: ->
      return moment.duration(0) unless @startTimeValid && @endTimeValid
      start = moment.parseZone(@timeEntry.startTime)
      end = moment.parseZone(@timeEntry.endTime)
      end.diff(start)
    taskErrors: -> if @timeEntry.task then [] else ['empty']
    commentErrors: -> if @timeEntry.task == 'misc' and !@timeEntry.comment then ['empty'] else []
    errorsObject: ->
      startTime: @startTimeErrors
      endTime: @endTimeErrors
      task: @taskErrors
      comment: @commentErrors
    errors: ->
      _.pickBy @errorsObject, (a) -> a.length
    startTimeValid: -> !@startTimeErrors.length
    endTimeValid: -> !@endTimeErrors.length
    isTimeEntryValid: ->
      _.isEmpty(@errors)
    isGeneratorsValid: ->
      unless @unsaved
        _.isEmpty(@timeEntry.generators.filter (m) -> !m.generator.deleted && !m.isValid)
    isMileagesValid: ->
      unless @unsaved
        _.isEmpty(@timeEntry.mileages.filter (m) -> !m.mileage.deleted && !m.isValid)
    valid: ->
      if @unsaved
        @isTimeEntryValid
      else
        @isTimeEntryValid && @isMileagesValid && @isGeneratorsValid
    firstError: ->
      _.toPairs(@errors)[0]
  watch:
    valid: (value) ->
      @$emit('update:isValid', value)
    timeEntry:
      handler: -> @$emit('update:unsaved', true)
      deep: true
  filters:
    formatTime: (value) ->
      return unless value
      m = moment.parseZone(value)
      if m.isValid() then m.format('HH:mm') else ''
