import _ from 'lodash'
import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'
import TurbolinksAdapter from 'vue-turbolinks'

import CrewMemberEvents from 'components/crew_member_events.vue'
import TimeTracking from 'components/time_tracking'
import TimeEntries from 'components/time_entries.vue'
import TimeEntry from 'components/time_entry.vue'
import PauseItem from 'components/pause_item.vue'
import TimeInput from 'components/time_input.vue'
import MapCard from 'components/map_card.vue'
import Mileages from 'components/mileages.vue'
import Expense from 'components/expense.vue'
import PerDiem from 'components/per_diem.vue'
import Expenses from 'components/expenses.vue'
import Attachments from 'components/attachments.vue'
import Mileage from 'components/mileage.vue'
import Generators from 'components/generators.vue'
import Generator from 'components/generator.vue'


Vue.component 'TimeTracking', TimeTracking
Vue.component 'TimeInput', TimeInput
Vue.component 'TimeEntry', TimeEntry
Vue.component 'PauseItem', PauseItem
Vue.component 'TimeEntries', TimeEntries
Vue.component 'CrewMemberEvents', CrewMemberEvents
Vue.component 'MapCard', MapCard
Vue.component 'Mileages', Mileages
Vue.component 'Expense', Expense
Vue.component 'PerDiem', PerDiem
Vue.component 'Expenses', Expenses
Vue.component 'Attachments', Attachments
Vue.component 'Generators', Generators
Vue.component 'Generator', Generator
Vue.component 'Mileage', Mileage

Vue.use(VueI18n)
Vue.use(TurbolinksAdapter)

Vue.filter 'formatDuration', (value, param) ->
  duration = moment.duration(value, param)
  "#{duration.hours()}:#{_.padStart(duration.minutes(), 2, '0')}"

Vue.filter 'formatDate', (value, format) ->
  moment.parseZone(value).format(format || 'L')

$(document).on 'turbo:load', ->
  i18n = new VueI18n
    locale: $('body').data('locale')
    messages: window.messages

  $('.vue').each (index, el) ->
    template = $(el).data('template')
    new Vue
      i18n: i18n
      el: el
      template: template
