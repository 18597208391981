

















































module.exports =
  props: ['resources', 'timeId', 'generators', 'editable', 'generator', 'crew_member_id', 'date', 'isValid',
          'collapsed']

  data: ->
    error: null
    success: null
  computed:
    totalTime: ->
      start = moment.parseZone(@generator.startTime)
      end = moment.parseZone(@generator.endTime)
      end.diff(start)
    checkGenerator: ->
      @typeGenerator.filter (e) => e.id == @generator.itemId
    typeGenerator: ->
      @resources.filter (el) => el.category == "generator"
    valid: ->
      _.values(@errors).every((e) -> !e)
    errors: ->
      {
        resource: !@validateResource(@generator.itemId),
        start_time: !@validateResource(@generator.startTime),
        end_time: !@validateResource(@generator.endTime),
      }
  methods:
    expand: ->
      return unless @editable
      @$emit('expand')
      @$emit('update:collapsed', false)
    collapse: ->
        @$emit('update:collapsed', true)
    remove: ->
      @generator.deleted = true if confirm(@$t('generators.confirm_remove'))
    validateResource: (value) ->
      value != null
  watch:
    generator:
      handler: -> @$emit('update:unsaved', true)
      deep: true
    valid: (value) ->
      @$emit('update:isValid', value)
