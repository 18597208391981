























module.exports =
  props: ['resources', 'timeId', 'generators', 'editable', 'crew_member_id', 'date']
  data: ->
    error: null
    success: null
  computed:
    allGeneratorsValid: ->
      _(@generators).every('isValid')
    undeletedGenerators: ->
      @generators.filter (e) -> !e.generator.deleted
  methods:
    addGenerator: ->
      newGenerator = @generators.push
        generator:
          endTime: null
          startTime: null
          crewMemberId: @crew_member_id
          timeEntryId: @timeId
          itemId: null
          deleted: false
        collapsed: false
        isValid: false
        unsaved: true
      @onGeneratorExpand(newGenerator)
    onGeneratorExpand: (openGenerator) ->
      @generators.forEach (generator) ->
        generator.collapsed = openGenerator != generator if generator.isValid

