












module.exports =
  props: ['value', 'defaultDate', 'after', 'errors', 'class_name', 'inputId']
  data: ->
    timeString: @formatTime(@value)
  computed:
    error: ->
      switch
        when !@timeString.length then 'empty'
        when !@validTime then 'invalidTime'
        when !@isAfter then 'notAfter'
        else null
    validTime: ->
      /^(([0-1]?[0-9])|(2[0-3])):[0-5][0-9]$/.test(@timeString) &&
        @dateTime().isValid()
    isAfter: ->
        (!@after || @dateTime().isAfter(moment.parseZone(@after)))
    valid: -> !@error
  methods:
    formatTime: (date) ->
      m = moment.parseZone(date)
      if m.isValid() then m.format('HH:mm') else ''
    parseTime: (value) ->
      moment(value, 'HH:mm') if value && value != ''
    dateTime: ->
      time = @parseTime(@timeString)
      return unless time.isValid()
      date = moment.parseZone(@date())
      date.hour(time.hour())
      date.minute(time.minute())

      date
    date: ->
      @value || @defaultDate
    updateValue: (value) ->
      @$emit('input', @dateTime()) if @valid
    useNativeTimePicker: ->
      $('body').hasClass('mobile-device')
    inputType: ->
      if @useNativeTimePicker() then 'time' else 'text'
  watch:
    error: (error) ->
      @$emit('update:errors', error && [error] || [])
    value: (value) ->
      @timeString = @formatTime(value)
  mounted: ->
    @$emit('update:errors', [@error]) if @error
