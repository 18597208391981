addAttachment = (file, path, id)->
  data = new FormData
  data.append('file' , file)
  $.post
    url: "/#{path}/#{id}/add_attachment"
    data: data
    processData: false
    contentType: false
    headers:
      Accept: 'application/json'

removeAttachment = (attachmentId, path, id)->
  $.post
    url: "/#{path}/#{id}/remove_attachment?#{$.param(attachment_id: attachmentId)}"
    contentType: 'application/json'
    headers:
      Accept: 'application/json'

module.exports = { addAttachment, removeAttachment }
