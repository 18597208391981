import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  static targets = [ "link", "linkDiv", "btnGroup", "dropdownMenu" ]

  connect() {
    console.log("Connection 'driver-select 2'")
  }

  deleteSelection(e) {
    var assignment_id = e.target.dataset.assignmentIdValue;

    fetch("/event_series_assignments/"+assignment_id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ event_series_assignment: { id: assignment_id } })
    })
      .then(response => response.json())
      .then(data => {
        if(data.status === "ok"){
          if(e.target.dataset.assignmentTypeValue === "event"){
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[0].classList.toggle('hidden');
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[0].innerHTML = "";
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].classList.toggle('hidden');
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].innerHTML = "";

            document.getElementById(e.target.dataset.dayValue.replaceAll('"', '')+"-"+e.target.dataset.shortTitleValue+"-"+e.target.dataset.itemIdValue).classList.remove("assigned")
          } else {
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[0].classList.toggle('hidden');
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].classList.toggle('hidden');
            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].innerHTML = "";
            //document.getElementById(e.target.dataset.dayValue.replaceAll('"', '')+"-"+e.target.dataset.shortTitleValue+"-"+e.target.dataset.itemIdValue).classList.remove("assigned")

            document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).style.background = "";
          }
        } else {
          console.log("status ist ERROR: ", data)
        }
      })

    e.preventDefault();
  }

  dropdownSelection(e) {
    console.log("Dropdown Selection")

    //console.log(e.target);
    //console.log(e.target.dataset);
    //console.log("day: "+e.target.dataset.dayValue.replaceAll('"', ''));
    //console.log("employee: "+e.target.dataset.employeeIdValue);
    //console.log("roadshow id: "+e.target.dataset.roadshowIdValue);
    //console.log("roadshow title: "+e.target.dataset.shortTitleValue);

    var current_td_element_id = e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '');
    var this_element = this;

    fetch("/event_series_assignments", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ event_series_assignment: { crew_member_id: e.target.dataset.employeeIdValue, assignment_date: e.target.dataset.dayValue, event_series_id: e.target.dataset.roadshowIdValue, item_id: e.target.dataset.itemIdValue, assignment_type: e.target.dataset.assignmentTypeValue }, absence: { absence_type: e.target.dataset.absenceTypeValue } })
    })
      .then(response => response.json())
      .then(data => {
        if(data.status === "created"){
          //console.log("FETCH data: ", data)
          document.getElementById(current_td_element_id).dataset.driversPlanningAssignmentIdValue = data.assignment_id;
          document.getElementById(current_td_element_id).dataset.driversPlanningAssignmentValue = "true";
          document.getElementById(current_td_element_id).dataset.driversPlanningItemIdValue = e.target.dataset.itemIdValue;
          document.getElementById(current_td_element_id).dataset.shortTitleValue = e.target.dataset.shortTitleValue;

          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[0].classList.toggle('hidden');
          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].classList.toggle('hidden');
          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].innerHTML = "";
          var link = "<a tabindex='-1' onclick='return false;' href='#'>"+e.target.dataset.shortTitleValue+"</a>"
          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[0].innerHTML = link

          if(e.target.dataset.assignmentTypeValue === "event"){
            this_element.linkTarget.innerHTML = e.target.dataset.shortTitleValue
            // mark day in top table as green / assigned
            document.getElementById(e.target.dataset.dayValue.replaceAll('"', '')+"-"+e.target.dataset.shortTitleValue+"-"+e.target.dataset.itemIdValue).classList.add("assigned")
            document.getElementById(e.target.dataset.dayValue.replaceAll('"', '')+"-"+e.target.dataset.shortTitleValue+"-"+e.target.dataset.itemIdValue).style.background = "";
          } else {
            this_element.linkTarget.innerHTML = "";
            switch (e.target.dataset.absenceTypeValue) {
              case "vacation":
                document.getElementById(current_td_element_id).style.background = "#660066";
                break;
              case "sick":
                document.getElementById(current_td_element_id).style.background = "#ff99ff";
                break;
              case "not_available":
                document.getElementById(current_td_element_id).style.background = "#000000";
                break;
            }
          }
        }else{
          console.log("ERROR in fetch")
          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[0].classList.toggle('hidden');
          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].classList.toggle('hidden');
          document.getElementById(e.target.dataset.employeeIdValue+"-"+e.target.dataset.dayValue.replaceAll('"', '')).children[1].innerHTML = "";

          document.getElementById(e.target.dataset.dayValue.replaceAll('"', '')+"-"+e.target.dataset.shortTitleValue+"-"+e.target.dataset.itemIdValue).classList.remove("assigned")
        }
      });

    e.preventDefault();
  }
}
