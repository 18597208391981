import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "item" ]

  search(event) {
    this.query = event.currentTarget.value.toLowerCase()

    if (this.timeout) { clearTimeout(this.timeout) }

    this.timeout = setTimeout(this.searchIt.bind(this), 500)
  }

  searchIt() {
    this.itemTargets.forEach((item) => {
      const searchIndex = item.textContent.toLowerCase()
      if (searchIndex.includes(this.query)) {
        item.parentNode.classList.remove('hide')
      } else {
        item.parentNode.classList.add('hide')
      }
    })
  }

}
