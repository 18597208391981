import * as d3 from "d3"

class GanttChart
  constructor: (element, options) ->
    @chart = d3.select element
      .select 'svg'
    @dataFunc = options.data
    @cachedData = options.initialData || []
    @viewRange = options.viewRange || 'month'
    @viewDate = moment()

    @xAxisHeight = 20
    @yAxisWidth = 200
    @yAxisPadding = 10
    @lineHeight = 30
    @linePadding = 5
    @marginRight = 10

    @startDate = options.startDate
    @endDate = options.endDate

    @draw()

  loadData: (callback) ->
    @dataFunc @dateRange(@prevDate(), @nextDate()), (data) =>
      @cachedData = data
      callback data

  dateRange: (start = @viewDate, end = @viewDate)->
    [moment(start).startOf(@viewRange), moment(end).endOf(@viewRange)]

  setViewDate: (date) ->
    @viewDate = date
    @redraw()
    @reload()

  redraw: ->
    @update(@cachedData)

  reload: ->
    @loadData (data) => @update(data)

  nextDate: -> moment(@viewDate).add 1, @viewRange
  prevDate: -> moment(@viewDate).add -1, @viewRange

  next: -> @setViewDate @nextDate()
  prev: -> @setViewDate @prevDate()

  draw: ->
    @x = d3.scaleTime()
    @xAxis = d3.axisTop()
      .scale @x
      .ticks d3.timeDay
      .tickFormat (d) -> moment(d).format 'dd D'

    defs = @chart.append 'defs'
    defs.append 'clipPath'
      .attr 'id', 'xaxisClipPath'
      .append 'rect'
      .attr 'x', -@yAxisPadding
      .attr 'y', -@xAxisHeight
    defs.append 'clipPath'
      .attr 'id', 'canvasClipPath'
      .append 'rect'
      .attr 'x', 0
      .attr 'y', 0

    @chart.append 'g'
      .attr "class", 'xaxis'
      .attr 'overflow', 'hidden'
      .attr 'transform', "translate(#{@yAxisWidth}, #{@xAxisHeight})"
      .attr 'clip-path', 'url(#xaxisClipPath)'

    @chart.append 'g'
      .attr 'class', 'yaxis'
      .attr 'transform', "translate(0, #{@xAxisHeight + @linePadding})"

    @chart.append 'g'
      .attr 'class', 'canvas'
      .attr 'transform', "translate(#{@yAxisWidth}, #{@xAxisHeight + @linePadding})"
      .attr 'clip-path', 'url(#canvasClipPath)'

    @redraw()
    @reload()

  update: (data) ->
    $(@chart.node()).closest('.gantt-chart')
      .find('.currentMonth')
      .html(moment(@viewDate).format('MMMM YYYY'))

    @chartWidth = $(@chart.node()).width()
    @canvasWidth = @chartWidth - @yAxisWidth - @yAxisPadding - @marginRight
    @canvasHeight = (@lineHeight + @linePadding) * data.length
    @chartHeight = @xAxisHeight + @linePadding + @canvasHeight

    $(@chart.node()).height @chartHeight

    @chart.select('#xaxisClipPath rect')
      .attr 'width', @canvasWidth + @yAxisPadding
      .attr 'height', @chartHeight
    @chart.select('#canvasClipPath rect')
      .attr 'width', @canvasWidth
      .attr 'height', @canvasHeight

    @x.domain @dateRange()
    @x.range [0, @canvasWidth]

    @chart.select '.yaxis'
      .selectAll 'a.resource'
      .data data
      .enter()
      .append 'a'
      .attr 'xlink:href', (d, i) -> d.url
      .attr 'class', 'resource'
      .attr 'transform', (d, i) =>
        "translate(0, #{i * (@lineHeight + @linePadding)})"
      .append('text')
      .text (d, i) -> d.name
      .attr 'transform', "translate(#{@yAxisWidth - @yAxisPadding}, #{@lineHeight / 2})"
      .attr 'text-anchor', 'end'
      .attr 'height', "#{@lineHeight}"
      .attr 'dominant-baseline', 'central'

    @chart.select '.canvas'
      .selectAll 'g.resource'
      .data data
      .enter()
      .append 'g'
      .attr 'class', 'resource'
      .attr 'transform', (d, i) =>
        "translate(0, #{i * (@lineHeight + @linePadding)})"

    @chart.select 'g.xaxis'
      .transition()
      .call @xAxis

    resources = @chart.select('.canvas')
      .selectAll('g.resource')
      .data data, (d) -> d.id
      .selectAll 'a'
      .data ((d, i) -> d.events.concat(d.event_series)), (d) -> d.id

    resources.select('rect').transition()
      .attr "width", (d, i) => @x(@endDate(d)) - @x(@startDate(d))
      .attr "transform", (d, i) => "translate(#{@x(@startDate(d))}, 0)"

    resourcesNew = resources.enter()
      .append 'a'
      .attr 'xlink:href', (d, i) -> d.url
      .append 'rect'
      .attr "class", (d, i) -> "#{d.model_class} state-#{d.state}"
      .attr 'data-json', (d, i) -> window.d = d
      .attr "height", @lineHeight
      .attr "width", (d, i) => @x(@endDate(d)) - @x(@startDate(d))
      .attr "transform", (d, i) => "translate(#{@x(@startDate(d))}, 0)"

    resourcesNew.append "svg:title"
      .text (d, i) -> d.title

    resources.exit().remove()

  setDateRange: (start, end) ->
    @dateRange = [start, end]

$(document).on 'turbolinks:load turbo:load', ->
  $('.gantt-chart').each ->
    chart = new GanttChart @,
      data: (dateRange, func) =>
        base_url = $(@).data('resource-url')
        start = dateRange[0].toISOString()
        end = dateRange[1].toISOString()
        sep = if base_url.indexOf('?') == -1 then '?' else '&'
        $.getJSON("#{base_url}#{sep}events[start]=#{start}&events[end]=#{end}", func)
      startDate: (e) -> moment(e.start_date).startOf('day').toDate()
      endDate: (e) -> moment(e.end_date).endOf('day').toDate()
      viewRange: 'month'

    $(window).resize -> chart.redraw()
    $('.prev-month', @).click -> chart.prev()
    $('.next-month', @).click -> chart.next()
