import { Controller } from "stimulus"

export default class extends Controller {

  setDropMenuPosition(event) {
    if (event.target.tagName !== 'A') {
      return
    }

    let assignmentsContainer = event.target.closest('.assignments-container')
    let assignmentsContainerRect = assignmentsContainer.getBoundingClientRect()
    let clickedElementRect = event.target.getBoundingClientRect()
    let verticalDirection = clickedElementRect.top < assignmentsContainerRect.top + assignmentsContainerRect.height / 2 ? 'down' : 'up'
    let horizontalDirection = clickedElementRect.left < assignmentsContainerRect.left + assignmentsContainerRect.width / 2 ? 'left' : 'right'

    let url = new URL(event.target.href)
    url.searchParams.append('vertical_direction', verticalDirection)
    url.searchParams.append('horizontal_direction', horizontalDirection)

    event.target.href = url.href
  }

}
