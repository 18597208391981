import { Controller } from "stimulus"

export default class extends Controller {

  show(event) {
    this.element.querySelector("span").classList.add("hidden")
    this.element.querySelector("input[type='date']").classList.remove("hidden")
    this.element.querySelector("input[type='date']").removeAttribute("style")
  }

  change(event) {
    this.element.querySelector("span").classList.remove("hidden")
    this.element.querySelector("input[type='date']").classList.add("hidden")
    this.element.querySelector("input[type='date']").setAttribute("style", "display: none;")

    const url = new URL(window.location.href)
    url.searchParams.set("start_date", event.target.value)
    Turbo.visit(url.toString())
  }

}
