import LeafletMap from 'leaflet_map'

$(document).on 'turbo:load', ->
  $('.mapbox').each ->
    map = new LeafletMap(this)
    if geoJson = $(this).data('geojson')
      map.addGeoJson(geoJson)
    else if geoJsonUrl = $(this).data('geojsonUrl')
      map.loadGeoJson(geoJsonUrl)

    window.map = map
