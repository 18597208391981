import { Controller } from "stimulus"

export default class extends Controller {

  show(event) {
    event.preventDefault()

    this.element.querySelectorAll('.hidden').forEach((element) => {
      element.classList.remove('hidden')
    })

    event.target.remove()
  }

}
