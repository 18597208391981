

























































module.exports =
  props: ['crew_member_id']
  data: ->
    days: []
    events: []
    startOfWeek: moment().startOf('week')
  computed:
    previousWeekNumber: ->
      @currentWeekNumber - 1
    currentWeekNumber: ->
      @startOfWeek.week()
    nextWeekNumber: ->
      @currentWeekNumber + 1
    endOfWeek: ->
      @startOfWeek.clone().endOf('week')
    geojson: ->
      _(@events).chain()
        .filter (e) -> e.location && e.location.latitude
        .map (event) ->
          type: 'Feature'
          properties:
            id: event.id
            title: event.title
            description: "#{moment.parseZone(event.start).format('LL')}<br>#{event.location.name}"
            number: moment.parseZone(event.start).format('dd')
            color: event.stateColor
          geometry:
            type: 'Point'
            coordinates: [event.location.longitude, event.location.latitude]
        .value()
  mounted: ->
    @loadEvents()
  watch:
    startOfWeek: ->
      @loadEvents()
  methods:
    previousWeek: ->
      @startOfWeek = @startOfWeek.clone().subtract(7, 'days')
    nextWeek: ->
      @startOfWeek = @startOfWeek.clone().add(7, 'days')
    loadEvents: ->
      $.get "/events.json?start=#{@startOfWeek.toISOString()}&end=#{@endOfWeek.toISOString()}&with_crew_member=#{@crew_member_id}&state=confirmed", (data) =>
        @events = _.filter data, (event) =>
          moment.parseZone(event.start).isBetween(@startOfWeek, @endOfWeek)
        events = _.groupBy @events, (event) ->
          moment(event.start).startOf('day').toDate()
        @days = _.map [0..6], (day) =>
          date: @startOfWeek.clone().add(day, 'days')
          events: events[@startOfWeek.clone().add(day, 'days').toDate()] || []

