



























module.exports =
  props: ['dayRates', 'workingTime', 'url', 'user_per_diem']
  data: ->
    user_per_diem_json = JSON.parse(@user_per_diem)
    url = @url
    error: null
    success: null
    extreme_day: _.get(user_per_diem_json, 'extreme_day') || false
    country: _.get(user_per_diem_json, 'country') || ''
    breakfast: _.get(user_per_diem_json, 'breakfast') || false
    rates: JSON.parse(@dayRates)
  computed:
    valid: ->
      _(@country).isEmpty()
  methods:
    check: ->
      @$emit('perDiem', {country: @country, extreme_day: @extreme_day, breakfast: @breakfast})
    saveDiem: ->
      $.post
        url: @url,
        data: JSON.stringify
          country: @country
          breakfast: @breakfast
          extreme_day: @extreme_day
        contentType: 'application/json'
      .done (data) =>
        @error = null
        @success = 'save_successful'
      .fail =>
        @success = null
        @error = 'save_error'
