





import LeafletMap from 'leaflet_map'

export default
  props: ['geojson']
  watch:
    geojson: (value) ->
      @map.addGeoJson(value)
  mounted: ->
    @map = new LeafletMap(@$refs.map)
    @map.addGeoJson(@geojson)
