




































































module.exports =
  props: ['dayRates', 'expense_url', 'editable', 'unsaved', 'isValid', 'expense', 'collapsed', 'deleted', 'file']
  data: ->
    rates: JSON.parse(@dayRates)
    error: null
    success: null
    voucherTypes: ['taxi', 'hotel', 'toll', 'other']
    paymentMethods: ['visa', 'uta', 'bar', 'dkv', 'other']
  computed:
    valid: ->
      _.values(@errors).every((e) -> !e)
    errors: ->
      {
        custom_voucher_type: if @expense.voucher_type == 'other' then _.isEmpty(@expense.custom_voucher_type) else false,
        custom_payment_method: if @expense.payment_method == 'other' then _.isEmpty(@expense.custom_payment_method) else false,
        payment_method: _.isEmpty(@expense.payment_method),
        voucher_type: _.isEmpty(@expense.voucher_type),
        country: _.isEmpty(@expense.country),
        amount: !parseFloat(@expense.amount),
      }
  methods:
    expand: ->
      return unless @editable
      @$emit('expand')
      @$emit('update:collapsed', false)
    collapse: ->
      @$emit('update:collapsed', true) if @valid
    remove: ->
      @expense.deleted = true if confirm(@$t('expenses.confirm_remove'))
    onFileChange: (file) ->
      @$emit('update:file', file)
      @$emit('update:unsaved', true)
  watch:
    expense:
      handler: -> @$emit('update:unsaved', true)
      deep: true
    valid: (value) ->
      @$emit('update:isValid', value)
