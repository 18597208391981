



















































module.exports =
  props: ['mileage', 'resources', 'isValid', 'timeId', 'collapsed', 'unsaved', 'editable']
  data: ->
    error: null
    success: null
  computed:
    checkResource: ->
      @resources.filter (e) => e.id == @mileage.itemId
    checkMileage: ->
      (parseFloat(@mileage.endMileage) - parseFloat(@mileage.startMileage)).toFixed(1)
    valueValidation: ->
      parseFloat(@mileage.startMileage) < parseFloat(@mileage.endMileage)
    valid: ->
      _.values(@errors).every((e) -> !e)
    errors: ->
      {
        resource: !@validateResource(@mileage.itemId),
        start_mileage: !@validateNumber(@mileage.startMileage),
        end_mileage: !@validateNumber(@mileage.endMileage),
        diffValue: !@valueValidation
      }
  methods:
    expand: ->
      return unless @editable
      @$emit('expand')
      @$emit('update:collapsed', false)
    collapse: ->
      @$emit('update:collapsed', true)
    remove: ->
      @mileage.deleted = true if confirm(@$t('mileage.confirm_remove'))
    trimNumber: (e) ->
      number = e.target.value
      validatedNumber = number.match(/[0-9]+\.[0-9]{1}/)
      number = validatedNumber[0] if validatedNumber
      number
    validateNumber: (value) ->
      parsedValue = parseFloat(value)
      parsedValue >= 0 && value.match(/[0-9]+\.[0-9]{1}/)
    validateResource: (value) ->
      value != null
  watch:
    mileage:
      handler: -> @$emit('update:unsaved', true)
      deep: true
    valid: (value) ->
      @$emit('update:isValid', value)
