import { Controller } from "stimulus"

export default class extends Controller {

  mouseEnter(event) {
    if (event.target.hasAttribute("data-id")) {
      document.querySelectorAll(`div.event_assignment[data-id]`).forEach((assignment) => {
        assignment.classList.remove('assignment-highlight')
      })

      document.querySelectorAll(`div.event_assignment[data-id="${event.target.dataset.id}"]`).forEach((assignment) => {
        if (assignment != event.target) {
          assignment.classList.add('assignment-highlight')
          assignment.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        }
      })
    }
  }

  mouseLeave(event) {
    if (event.target.hasAttribute("data-id")) {
      document.querySelectorAll(`div.event_assignment[data-id="${event.target.dataset.id}"]`).forEach((assignment) => {
        assignment.classList.remove('assignment-highlight')
      })
    }
  }

}
