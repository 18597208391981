import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.assignmentElement = null
    this.startDate = this.element.dataset.startDate
    this.endDate   = this.element.dataset.endDate
    this.fromDate  = null
    this.tillDate  = null
    this.token = document.querySelector('meta[name="csrf-token"]').content

    this._refreshSelection.bind(this)
    this._updateAssignment.bind(this)
  }

  mouseDown(event) {
    if (!event.target.dataset.id) return

    this.assignmentElement = event.target.closest('div[data-id]')
    if (this.assignmentElement) {
      this.fromDate = this.assignmentElement.closest('td[data-date]').dataset.date
    }
  }

  mouseLeave(event) {
    this.fromDate = null
    this.tillDate = null
    this.assignmentElement = null

    this.element.querySelectorAll('td.day[data-date] div.selected').forEach((assignment) => {
      assignment.classList.remove('selected')
    })
  }

  mouseOver(event) {
    if (this.assignmentElement && event.target) {
      let td = event.target.closest('td.day[data-date]')

      if (td) {
        this.tillDate = td.dataset.date
        this._refreshSelection()
      }
    }
  }

  mouseUp(event) {
    if (this.assignmentElement && this.tillDate) {
      this._updateAssignment()
    }

    this.fromDate = null
    this.tillDate = null

    this._refreshSelection()

    this.assignmentElement = null
  }

  _assignmentId() {
    if (!this.assignmentElement) return null

    return this.assignmentElement.dataset.id
  }

  _isSelectable(_date) {
    if (!this.fromDate) return false
    if (!this.tillDate) return false

    let fromDate = new Date(this.fromDate)
    let tillDate = new Date(this.tillDate)
    let date     = new Date(_date)

    if (fromDate > tillDate) {
      let tmp = fromDate
      fromDate = tillDate
      tillDate = tmp
    }

    if (date >= fromDate && date <= tillDate) {
      return true
    }
    return false
  }

  _itemElement() {
    if (!this.assignmentElement) return null

    return this.assignmentElement.closest('tr.assignments-row')
  }

  _refreshSelection() {
    if (this.assignmentElement) {
      this._itemElement().querySelectorAll('td.day[data-date]').forEach((td) => {
        let firstAssignment = td.querySelector('div')

        if (this._isSelectable(td.dataset.date)) {
          firstAssignment.classList.add('selected')
        } else {
          firstAssignment.classList.remove('selected')
        }
      })
    }
  }

  _updateAssignment() {
    let url = '/assignments/' + this._assignmentId() + '?change_till_date=' + this.tillDate + '&start_date=' + this.startDate + '&end_date=' + this.endDate
    fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json',
        'Accept': 'text/vnd.turbo-stream.html'
      },
      credentials: 'same-origin'
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}
