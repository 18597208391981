




















import attachmentsHelpers from '../helpers/attachments'

export default
  props: ['dayRates', 'expenses_json', 'url', 'editable', 'crew_member_id']
  data: ->
    expenses: @loadExpense(JSON.parse(@expenses_json))
    expenses_success: null
    expenses_error: null
  computed:
    unsavedExpense: ->
      _(@expenses).some('unsaved') || _(@expenses).some((e) -> e.expense.deleted)
    undeletedExpenses: ->
      @expenses.filter (e) -> !e.expense.deleted
    allExpensesValid: ->
      _(@undeletedExpenses).every('isValid')
  methods:
    add_expenses: ->
      newExpense = @expenses.push
        expense:
          voucher_type: ''
          custom_voucher_type: ''
          crew_member_id: @crew_member_id
          amount: ''
          payment_method: ''
          custom_payment_method: ''
          country: ''
          deleted: false
        collapsed: false
        file: false
        isValid: false
        unsaved: true
      @onExpandExpenses(newExpense)
    onExpandExpenses: (openExpense) ->
      @expenses.forEach (expense) ->
        expense.collapsed = openExpense != expense if expense.isValid
    loadExpense: (json) ->
      _.map json, (expense) ->
        expense: _.merge(expense, { deleted: false, unsaved: false })
        file: false
        collapsed: true
        isValid: true
        unsaved: false
    saveExpenses: ->
      $.post
        url: @url,
        data: JSON.stringify
          expenses:
            @expenses.map (e) -> _.mapKeys(e.expense, (v, k) -> _.snakeCase(k))
        contentType: 'application/json'
      .done (data) =>
        @saveFiles(data.expenses)
        .then (expensesWithAttachments) =>
          # set attachments to expenses after they have been saved
          @expenses = @loadExpense(data.expenses.map (expense) =>
            expensesWithAttachments.find((a) -> a && a.id == expense.id) || expense
          )
          @expenses_error = null
          @expenses_success = 'save_successful'
    saveFiles: (savedExpenses) ->
      Promise.all savedExpenses.map (expense, index) =>
        file = @undeletedExpenses[index].file
        return unless file
        attachmentsHelpers.addAttachment(file, 'expenses', expense.id)
