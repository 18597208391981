import { Controller } from "stimulus"

export default class extends Controller {

  static tooltip = null

  connect() {
    this.tooltip = document.createElement('div')
    this.tooltip.classList.add('assignment-tooltip', 'hide')
    this.tooltip.id = `assignment-tooltip`
    this.element.appendChild(this.tooltip)

    this.show.bind(this)
  }

  show(event) {
    if (!event.target.dataset.tooltipDate) {
      return
    }

    this.tooltip.innerHTML = ''
    this.tooltip.classList.add('hide')

    for (const key in event.target.dataset) {
      if (key.indexOf('tooltip') === -1) { continue }

      const div = document.createElement('div')
      div.classList.add(key)
      div.innerHTML = event.target.dataset[key]
      this.tooltip.appendChild(div)
    }

    this.tooltip.classList.remove('hide')

    const rect = document.body.getBoundingClientRect()
    const targetRect = event.target.getBoundingClientRect()
    const halfWidth = rect.width / 2
    const halfHeight = rect.height / 2

    if (targetRect.top > halfHeight) {
      this.tooltip.style.top = `${targetRect.top - this.tooltip.clientHeight - 10}px`
    } else {
      this.tooltip.style.top = `${targetRect.bottom + 10}px`
    }

    if (targetRect.left > halfWidth) {
      this.tooltip.style.left = `${targetRect.left + targetRect.width - this.tooltip.clientWidth}px`
    } else {
      this.tooltip.style.left = `${targetRect.left}px`
    }
  }

  hide(event) {
    this.tooltip.classList.add('hide')
  }

}
