import { Controller } from "stimulus"
export default class extends Controller {

  static values = {
    employee: String,
    employeeShortname: String,
    day: String,
    id: String,
    assignment: Boolean,
    assignmentId: String
  }

  static targets = [ "tourSpreadsheet", "employeeSpreadsheet" ]

  initialize() {
    this.isDragging = false
  }

  connect() {
    //console.log("Connection: DriversPlanning")
  }

  disconnect() {
    console.log("disconnecting...")
  }

  render_select(e){
    //console.log("######### clicki... ########");
    //console.log(this.assignmentValue)

    if(this.assignmentValue === true){
      this.element.children[1].innerHTML = ""
      this.element.children[0].classList.toggle("hidden")
      this.element.children[1].classList.toggle("hidden")

      this.assignmentValue = false
    }

    if(this.element.children[1].innerHTML.trim().length === 0){
      var start_date = document.getElementById("drivers_planning_start_at").value;

      $.ajax({
        method: "POST",
        url: "/drivers_plannings/"+this.idValue+"/render_select",
        dataType: "script",
        data: { employee_id: this.employeeValue, day: this.dayValue, employee_shortname: this.employeeShortnameValue, start_date: start_date }
      });
    } else {
    }

    e.preventDefault()
  }

  startDrag(e){
    console.log("start dragging...");
    this.isDragging = true;
    this.application.currentDraggedEmployee = this.employeeValue;

    if(this.assignmentValue == true){
      this.application.currentDraggedAssignment = this.assignmentIdValue;
    }
    e.target.classList.add("dragged");
    e.preventDefault()
  }

  duringDragging(e){
    if(this.employeeValue === this.application.currentDraggedEmployee){
      if (e.buttons == 1) {
        console.log("now dragging...");
        $.ajax({
          method: "POST",
          url: "/drivers_plannings/"+this.idValue+"/copy_assignment",
          dataType: "script",
          data: { employee_id: this.employeeValue, day: this.dayValue, assignment_id: this.application.currentDraggedAssignment, employee_shortname: this.employeeShortnameValue }
        });
      }
    }

    e.preventDefault()
  }

  stopDrag(e){
    console.log("stop dragging...");
    this.isDragging = false;
    this.application.currentDraggedAssignment = null; // really usefull? we will see...
    this.application.currentDraggedEmployee = null;

    Array.from(document.querySelectorAll('.dragged')).forEach(function(el) { 
      el.classList.remove('dragged');
    });

    e.preventDefault()
  }
}
