


























































timeHelpers = require '../helpers/time_entries'
resourceHelpers = require '../helpers/resources'

module.exports =
  props: ['rest_day', 'timeEntries', 'pauses', 'date', 'url', 'tasks', 'editable', 'workingTime',
          'resources', 'date', 'crew_member_id']
  data: ->
    save_resources: false
    error: null
    success: null
    crewMember: @$props.crew_member_id
    show_message: false
  computed:
    allcollapsed: ->
      _(@timeEntries).every('collapsed')
    allpausescollapsed: ->
      _(@pauses).every('collapsed')
    allValid: ->
      if @rest_day
        @allTimeEntriesValid
      else
        @allPausesValid && @allTimeEntriesValid && !@shortPauseNeeded && !@longPauseNeeded
    allPausesValid: ->
      _(@pauses).every('isValid')
    allTimeEntriesValid: ->
      _(@timeEntries).every('isValid')
    unsaved: ->
      _(@timeEntries).some('unsaved') || _(@timeEntries).some((e) -> e.timeEntry.deleted) ||
        _(@pauses).some('unsaved') || _(@pauses).some((e) -> e.pause.deleted)
    undeletedTimeEntries: ->
      @timeEntries.filter (e) -> !e.timeEntry.deleted
    crewMemberUndeletedTimeEntries: ->
      @undeletedTimeEntries.filter (e) =>
        e.timeEntry.crewMemberId == @crewMember
    undeletedPauses: ->
      @pauses.filter (e) -> !e.pause.deleted
    crewMemberUndeletedPauses: ->
      @undeletedPauses.filter (e) =>
        e.pause.crewMemberId == @crewMember
    pauseTime: ->
      _(@undeletedPauses).sumBy (e) => timeHelpers.entryDuration(e.pause)
    shortPauseNeeded: ->
      @workingTime >= 21600000 && @workingTime < 32400000 && @pauseTime < 1800000
    longPauseNeeded: ->
      @workingTime >= 32400000 && @pauseTime < 2700000
    crewMemberTimeEntries: ->
      @timeEntries.filter (e) => e.timeEntry.crewMemberId == @crewMember
    crewMemberPauses: ->
      @pauses.filter (e) => e.pause.crewMemberId == @crewMember
  methods:
    add: ->
      newEntry = @timeEntries.push
        timeEntry:
          startTime: ''
          endTime: ''
          task: ''
          comment: ''
          crewMemberId: @crewMember
          deleted: false
          new: true
        collapsed: false
        isValid: false
        unsaved: true
      @onTimeEntryExpand(newEntry)
    checkRestDay: ->
      if @rest_day == true
        @show_message = true
    addPause: ->
      newEntry = @pauses.push
        pause:
          startTime: ''
          endTime: ''
          comment: ''
          deleted: false
          crewMemberId: @crewMember
        collapsed: false
        isValid: false
        unsaved: true
      @onPauseExpand(newEntry)
    onPauseExpand: (pause) -> @doResourceExpand('pauses', pause)
    onTimeEntryExpand: (timeEntry) -> @doResourceExpand('timeEntries', timeEntry)
    doResourceExpand: (resourceKey, openItem) ->
      @[resourceKey].forEach (item) ->
        item.collapsed = openItem != item if item.isValid
    save: ->
      $.post
        url: @url,
        data: JSON.stringify
          time_entries:
            resourceHelpers
              .prepareItemsToSave(@crewMemberTimeEntries, 'timeEntry')
              .map (e) ->
                _.assign(e, {mileages: resourceHelpers.prepareItemsToSave(e.mileages, 'mileage')})
                _.assign(e, {generators: resourceHelpers.prepareItemsToSave(e.generators, 'generator')})
          pauses:
            @crewMemberPauses
              .filter (e) -> !e.pause.deleted || !e.unsaved
              .map (e) -> _.mapKeys(e.pause, (v, k) -> _.snakeCase(k))
        contentType: 'application/json'
      .done (data) =>
        @error = null
        @success = 'save_successful'
        @$emit 'saved', data
      .fail =>
        @success = null
        @error = 'save_error'
