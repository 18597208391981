





































module.exports =
  props: ['pause', 'collapsed', 'defaultDate', 'isValid', 'unsaved', 'deleted', 'editable']
  data: ->
    startTimeErrors: []
    endTimeErrors: []
  methods:
    expand: ->
      return unless @editable
      @$emit('expand')
      @$emit('update:collapsed', false)
    collapse: ->
      @$emit('update:collapsed', true) if @valid
    remove: ->
      @pause.deleted = true if confirm(@$t('pause.confirm_remove'))
  computed:
    totalTime: ->
      return moment.duration(0) unless @startTimeValid && @endTimeValid
      start = moment.parseZone(@pause.startTime)
      end = moment.parseZone(@pause.endTime)
      end.diff(start)
    errorsObject: ->
      startTime: @startTimeErrors
      endTime: @endTimeErrors
    errors: ->
      _.pickBy @errorsObject, (a) -> a.length
    startTimeValid: -> !@startTimeErrors.length
    endTimeValid: -> !@endTimeErrors.length
    valid: ->
      _.isEmpty(@errors)
    firstError: ->
      _.toPairs(@errors)[0]
  watch:
    valid: (value) ->
      @$emit('update:isValid', value)
    pause:
      handler: -> @$emit('update:unsaved', true)
      deep: true
  filters:
    formatTime: (value) ->
      return unless value
      m = moment.parseZone(value)
      if m.isValid() then m.format('HH:mm') else ''
