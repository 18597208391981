import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    let today = new Date().toISOString().slice(0,10)

    this.element.querySelectorAll(`[data-date="${today}"]`).forEach((day) => {
      day.classList.add('today')
    })
  }

}
