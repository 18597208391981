import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.filter.bind(this)
  }

  filter(event) {
    this.element.querySelectorAll('tr.assignments-row').forEach((row) => {
      if (event.currentTarget.value == '') {
        this.showAll(event, row)
      } else if (event.currentTarget.value.startsWith('filter')) {
        this[event.currentTarget.value](event, row)
      } else {
        this.filterByEventSeries(event, row)
      }
    })
  }

  filterByEventSeries(event, row) {
    if (row.querySelectorAll(`.assignments-description[data-event-series-ids~="${event.currentTarget.value}"], .event_assignment[data-event-series-id="${event.currentTarget.value}"]`).length > 0) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  filterItemsWithIncompleteEventAssignments(event, row) {
    if (row.dataset.crewMemberId || (row.dataset.itemId && row.dataset.needsDriver == 'true' && row.querySelectorAll(`.event_assignment.incomplete`).length > 0)) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  filterCrewMemberHasAssignments(event, row) {
    if ((row.dataset.crewMemberId && row.querySelectorAll('[data-id]').length > 0) || row.dataset.itemId) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  filterHasAssignments(event, row) {
    if (row.querySelectorAll('[data-id]').length > 0) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  filterItemHasAssignments(event, row) {
    if ((row.dataset.itemId && row.querySelectorAll('[data-id]').length > 0) || row.dataset.crewMemberId) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  filterItemsWithDriver(event, row) {
    if (row.dataset.crewMemberId || (row.dataset.itemId && row.dataset.needsDriver == 'true')) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  filterItemsWithoutDriver(event, row) {
    if (row.dataset.crewMemberId || (row.dataset.itemId && row.dataset.needsDriver == 'false')) {
      row.classList.remove('hide')
    } else {
      row.classList.add('hide')
    }
  }

  showAll(event, row) {
    row.classList.remove('hide')
  }

}
