























module.exports =
  props: ['resources', 'timeId', 'mileages', 'editable', 'crew_member_id']
  data: ->
    error: null
    success: null
  computed:
    allMileagesValid: ->
      _(@mileages).every('isValid')
    undeletedMileages: ->
      @mileages.filter (e) -> !e.mileage.deleted
  methods:
    trimNumber: (e) ->
      number = e.target.value
      validatedNumber = number.match(/[0-9]+\.[0-9]{1}/)
      number = validatedNumber[0] if validatedNumber
      number

    validateNumber: (value) ->
      parsedValue = parseFloat(value)
      parsedValue >= 0 && value.match(/[0-9]+\.[0-9]{1}/)
    validateResource: (value) ->
      value != null
    addMileage: ->
      newMileage = @mileages.push
        mileage:
          startMileage: ''
          endMileage: ''
          crewMemberId: @crew_member_id
          timeEntryId: @timeId
          itemId: null
          deleted: false
        collapsed: false
        isValid: false
        unsaved: true
      @onMileageExpand(newMileage)
    onMileageExpand: (openMileage) ->
      @mileages.forEach (mileage) ->
        mileage.collapsed = openMileage != mileage if mileage.isValid

